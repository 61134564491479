














































































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, PropSync, Provide, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    AccountDetail: () => import('./account-detail.vue'),
    ConnectWallet: () => import('./connect-wallet.vue'),
  },
})
export default class NavigationBar extends Vue {
  @PropSync('drawer') drawerDialog!: boolean

  @Provide() walletStore = walletStore

  async login() {
    const res = await this.walletStore.signIn()
    if (res) this.walletStore.changeShowConnectDialog(false)
  }

  get path() {
    if (!this.$route.matched.length) {
      return ''
    }
    return this.$route.matched[0].path
  }

  get height() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return 112
    }
    return 74
  }
}
