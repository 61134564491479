var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"navigation-bar",attrs:{"height":_vm.height,"app":"","flat":""}},[_c('div',{staticClass:"fill-width mx-auto d-flex justify-space-between justify-md-start mt-md-6",staticStyle:{"max-width":"1304px"}},[_c('div',{staticClass:"mr-8 d-flex align-center"},[_c('router-link',{attrs:{"to":"/vault"}},[_c('v-img',{attrs:{"max-width":_vm.$vuetify.breakpoint.lgAndUp ? '226' : '148',"src":require('@/assets/icons/logo.svg')}})],1)],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-8 flex-box-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path === '/summary' ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" DASHBOARD ")])]}}],null,false,3861576839)},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mr-8 flex-box-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path.includes('projects') ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" PROJECT ")])]}}],null,false,2228047118)},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mr-8 flex-box-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path.includes('projects') ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" FORUM ")])]}}],null,false,3837049144)},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mr-8 flex-box-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-decoration-none",class:_vm.path.includes('projects') ? 'primary--text' : 'white--text'},'div',attrs,false),on),[_vm._v(" MARKET ")])]}}],null,false,1980962495)},[_c('span',[_vm._v("Coming soon")])])],1),_c('div',{staticClass:"mr-8 flex-box-center"},[_c('router-link',{staticClass:"text-decoration-none",class:_vm.path === '/vault' ? 'primary--text' : 'white--text',attrs:{"to":"/vault"}},[_vm._v(" MINT ")])],1)]):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{staticClass:"spacer d-flex justify-end align-center"},[(_vm.walletStore.account)?_c('v-sheet',{staticClass:"transparent position-relative",attrs:{"width":"284"}},[(!_vm.walletStore.isKycVerified)?_c('v-img',{staticClass:"position-absolute",staticStyle:{"left":"79px","top":"-35px"},attrs:{"src":require('@/assets/icons/tootltip.svg'),"width":"126"}}):_vm._e(),_c('AccountDetail',{attrs:{"drawer":_vm.drawerDialog},on:{"update:drawer":function($event){_vm.drawerDialog=$event}}})],1):_vm._e(),_c('v-sheet',{staticClass:"transparent ml-2",attrs:{"width":"193"}},[_c('ConnectWallet',{attrs:{"drawer":_vm.drawerDialog},on:{"update:drawer":function($event){_vm.drawerDialog=$event}}})],1)],1):_vm._e(),(!_vm.$vuetify.breakpoint.lgAndUp)?_c('div',[_c('v-btn',{attrs:{"ripple":false,"color":"algo-grey darken-2","icon":""},on:{"click":function($event){_vm.drawerDialog = !_vm.drawerDialog}}},[(!_vm.drawer)?_c('v-img',{attrs:{"src":require('@/assets/icons/menu.svg'),"max-width":"19px"}}):_c('v-img',{attrs:{"src":require('@/assets/icons/close.svg'),"max-width":"19px"}})],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }